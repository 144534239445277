import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ESource, IBotType, IChatInfo, ICurrentAI } from 'src/types';
import rf from 'src/requests/RequestFactory';

interface IChatGptState {
  data: IChatInfo[];
  bots: IBotType[];
  detail?: IChatInfo;
  loading: boolean;
  currentAI: ICurrentAI;
}

const initialState: IChatGptState = {
  data: [],
  bots: [],
  loading: true,
  currentAI: { source: ESource.CHATGPT, bot_id: '' },
};

export const fetchBots = createAsyncThunk('bot/chat', async () => {
  try {
    const response = await rf.getRequest('ChatRequest').getBots();

    return response;
  } catch (error) {
    return error;
  }
});

export const fetchChatConversations = createAsyncThunk(
  'conversations/chat',
  async (params: ICurrentAI) => {
    try {
      const response = await rf
        .getRequest('ChatRequest')
        .getChatConversations(params);
      if (response) {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
);

export const chatGpt = createSlice({
  name: 'chatStore',
  initialState,
  reducers: {
    setDataChatGpt: (
      state: IChatGptState,
      action: { payload: { data: IChatInfo[] } },
    ) => {
      state.data = action.payload.data;
    },
    setCurrentAI: (state: IChatGptState, action: { payload: ICurrentAI }) => {
      state.currentAI = { ...state.currentAI, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChatConversations.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchChatConversations.rejected, (state) => {
      state.loading = false;
      state.data = [];
    });
    builder.addCase(fetchChatConversations.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchBots.fulfilled, (state, action) => {
      state.bots = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchBots.rejected, (state) => {
      state.loading = false;
      state.bots = [];
    });
    builder.addCase(fetchBots.pending, (state) => {
      state.loading = true;
    });
  },
});

export const { setDataChatGpt, setCurrentAI } = chatGpt.actions;
export default chatGpt.reducer;
