export interface IChatInfo {
  _id: string;
  user_id: string;
  source: string;
  model: string;
  messages: IMessage[];
}

export interface IMessage {
  role: ERole;
  content: string;
}

export interface ICurrentAI {
  source: ESource;
  bot_id?: string;
}

export interface IChatGptDetail {
  _id: string;
  temperature: number;
  model_type: string;
  source: string;
  user_id: string;
  messages: IMessage[];
}

export enum EAIMode {
  CHATGPT = '/chat-gpt',
  AZURE_OPENAI = '/azure-open-ai',
}

export enum ERole {
  USER = 'user',
  ASSISTANT = 'assistant',
}

export enum ESource {
  AZURE_OPENAI = 'AZURE_OPENAI',
  CHATGPT = 'CHATGPT',
}

export interface IBotType {
  _id: string;
  source: ESource;
  name: string;
  model_id?: string;
  model_method?: string;
  user_id: string;
  created_at: Date;
  backend_method?: string;
  backend_approach?: string;
  search_index_name?: string;
}

export interface IParams {
  bot_id: string;
  source?: ESource;
  sort?: '_id,asc' | '_id,desc';
}
